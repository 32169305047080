import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Form = () => {
  const [formData, setFormData] = useState({
    communicationPreferences: "", // Only one option will be stored here
    projectSocialLinks: {
      twitter: "",
      telegram: "",
      discord: "",
    },
    website: "",
    contractAddress: "",
    seekingConsultation: {
      liveStreaming: false,
      influencerMarketing: false,
    },
    communicationDetails: "", // To store the contact information based on selected communication method
  });

  const [captchaVerified, setCaptchaVerified] = useState(false); // State for CAPTCHA verification
  const [referenceNumber, setReferenceNumber] = useState(""); // To store generated reference number
  const [submitted, setSubmitted] = useState(false); // To track if form was submitted

  // Generate a unique reference number
  const generateReferenceNumber = () => {
    return `REF-${Math.random().toString(36).substring(2, 10).toUpperCase()}-${Date.now()}`;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // For communicationPreferences (radio buttons for single selection)
    if (name === "communicationPreferences") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        communicationPreferences: value,
        communicationDetails: "", // Reset communicationDetails whenever communication preference changes
      }));
    }
    // For communicationDetails input (this will capture the contact info based on preference)
    else if (name === "communicationDetails") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        communicationDetails: value,
      }));
    }
    // For social media inputs
    else if (name in formData.projectSocialLinks) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        projectSocialLinks: { ...prevFormData.projectSocialLinks, [name]: value },
      }));
    }
    // For nested consultation checkboxes (seekingConsultation)
    else if (name.includes("seekingConsultation")) {
      const fieldName = name.split(".")[1]; // Extract 'liveStreaming' or 'influencerMarketing'
      setFormData((prevFormData) => ({
        ...prevFormData,
        seekingConsultation: { ...prevFormData.seekingConsultation, [fieldName]: checked },
      }));
    }
    // For other fields
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      alert("Please complete the CAPTCHA verification.");
      return;
    }

    // Generate reference number
    const newReferenceNumber = generateReferenceNumber();
    setReferenceNumber(newReferenceNumber);

    // Define subject and body for mailto
    const subject = "New Form Submission";
    const body = `Reference Number: ${newReferenceNumber}%0A
      Communication Preferences: ${formData.communicationPreferences || "None"}%0A
      Preferred Contact Information: ${formData.communicationDetails || "Not provided"}%0A
      Project Social Links:%0A
      Website: ${formData.website}%0A
      Contract Address: ${formData.contractAddress}%0A
      Twitter: ${formData.projectSocialLinks.twitter}%0A
      Telegram: ${formData.projectSocialLinks.telegram}%0A
      Discord: ${formData.projectSocialLinks.discord}%0A
      Consultation:%0A
      Live Streaming: ${formData.seekingConsultation.liveStreaming ? "Yes" : "No"}%0A
      Influencer Marketing: ${formData.seekingConsultation.influencerMarketing ? "Yes" : "No"}`;

    // URL encode the body content for safety
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    // Construct the mailto link
    const mailtoLink = `mailto:cabalmarketingcampaigns@gmail.com?subject=${encodedSubject}&body=${encodedBody}`;

    // Open the email client
    window.location.href = mailtoLink;

    // Set form submitted state to true to show confirmation message
    setSubmitted(true);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(value ? true : false); // Set CAPTCHA verification status
  };

  return (
    <div className="form-container">
      <header className="header">
        <p className="customer-support-message">
          Customer Support will reply within 24 hours.
        </p>
        <p className="customer-support-message"> 
         Please have your reference number.
        </p>
    <div className="home-button-container">
      <Link to="/" className="home-button">
        Home
      </Link>
    </div>
      </header>
      {submitted ? (
        <div className="submission-confirmation">
          <h3>Form Submitted Successfully!</h3>
          <p>Your reference number is: <strong>{referenceNumber}</strong></p>
          <p>Thank you for your submission. We will review your information and get back to you soon.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-sections">
            {/* Communications Section */}
            <fieldset className="communications">
              <legend>Communication Preferences</legend>
              <div className="label-group">
                <label>
                  <input
                    type="radio"
                    name="communicationPreferences"
                    value="Email"
                    checked={formData.communicationPreferences === "Email"}
                    onChange={handleChange}
                  />
                  Email
                </label>
                <label>
                  <input
                    type="radio"
                    name="communicationPreferences"
                    value="Twitter"
                    checked={formData.communicationPreferences === "Twitter"}
                    onChange={handleChange}
                  />
                  Twitter
                </label>
                <label>
                  <input
                    type="radio"
                    name="communicationPreferences"
                    value="Telegram"
                    checked={formData.communicationPreferences === "Telegram"}
                    onChange={handleChange}
                  />
                  Telegram
                </label>
                <label>
                  <input
                    type="radio"
                    name="communicationPreferences"
                    value="Discord"
                    checked={formData.communicationPreferences === "Discord"}
                    onChange={handleChange}
                  />
                  Discord
                </label>
              </div>

              {/* Communication Details Input - Displayed based on selected preference */}
              {formData.communicationPreferences && (
                <div className="communication-details">
                  <label>
                    {formData.communicationPreferences} Contact:
                    <input
                      type="text"
                      name="communicationDetails"
                      value={formData.communicationDetails}
                      placeholder={`Enter your ${formData.communicationPreferences} details`}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              )}
            </fieldset>

            {/* Project Links Section */}
            <fieldset className="project-links">
              <legend>Project Links</legend>
              <label>
                Website
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  placeholder="Enter your website URL"
                  onChange={handleChange}
                />
              </label>
              <label>
                Contract Address
                <input
                  type="text"
                  name="contractAddress"
                  value={formData.contractAddress}
                  placeholder="Enter your contract address"
                  onChange={handleChange}
                />
              </label>
              <label>
                Twitter
                <input
                  type="text"
                  name="twitter"
                  value={formData.projectSocialLinks.twitter}
                  placeholder="Enter Twitter link"
                  onChange={handleChange}
                />
              </label>
              <label>
                Telegram
                <input
                  type="text"
                  name="telegram"
                  value={formData.projectSocialLinks.telegram}
                  placeholder="Enter Telegram link"
                  onChange={handleChange}
                />
              </label>
              <label>
                Discord
                <input
                  type="text"
                  name="discord"
                  value={formData.projectSocialLinks.discord}
                  placeholder="Enter Discord link"
                  onChange={handleChange}
                />
              </label>
            </fieldset>
          </div>

          {/* Consultations Section */}
          <fieldset className="consultations">
            <legend>Consultation</legend>
            <div className="label-consult">
              <label>
                <input
                  type="checkbox"
                  name="seekingConsultation.liveStreaming"
                  checked={formData.seekingConsultation.liveStreaming}
                  onChange={handleChange}
                />
                Live Streaming Consultation
              </label>
              <label>
                <input
                  type="checkbox"
                  name="seekingConsultation.influencerMarketing"
                  checked={formData.seekingConsultation.influencerMarketing}
                  onChange={handleChange}
                />
                Influencer Consultation
              </label>
            </div>
          </fieldset>

          {/* CAPTCHA Section */}
<div className="captcha-container">
  <ReCAPTCHA
    sitekey="6Ld_a5kqAAAAACILCgQhdHfn_jXbPHRnRb6VCwbs" // Replace with your reCAPTCHA site key
    onChange={handleCaptchaChange} // Capture the CAPTCHA response
  />
</div>
 

          {/* Submit Button */}
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default Form;
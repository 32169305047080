import React, { useState } from "react";

function Home() {
  const [activeTopic, setActiveTopic] = useState(null);

  const toggleFaq = (key) => {
    setActiveTopic((prev) => (prev === key ? null : key)); // Toggle active topic
  };

  const getContent = () => {
    switch (activeTopic) {
      case "about":
        return (
          <div className="faq-content">
            <p>
              Welcome to Cabal Marketing, where the cryptic meets the crypto.
              We are more than a bridge between developers and influencers – we
              are the gatekeepers of influence, the architects of momentum, and
              the stewards of your project's ascension.
            </p>
            <p>
              In the labyrinthine world of Web3, not all paths lead to success.
              Only those who dare to join the Cabal gain access to our exclusive
              network of influencers – voices that move markets, shape
              narratives, and spark movements. With us, you don’t just build a
              community; you recruit followers to your cause.
            </p>
            <p>
              Our methods are precise, our connections unparalleled, and our
              results undeniable. From shadowy whispers of strategy to the roar
              of viral campaigns, we ensure your project doesn’t just exist – it
              thrives in the spotlight of those who truly matter.
            </p>
            <p>The Cabal is waiting. Will you answer the call?</p>
          </div>
        );
      case "services":
        return (
          <div className="faq-content">
            <ul>
              <li>
                <strong>Influencer Marketing:</strong> Connect with top influencers in the crypto space.
              </li>
              <li>
                <strong>Viral Campaigns:</strong> Boost visibility with viral strategies.
              </li>
              <li>
                <strong>Token Branding:</strong> Build a powerful brand identity.
              </li>
              <li>
                <strong>Community Building:</strong> Grow and engage a thriving community.
              </li>
            </ul>
          </div>
        );
      case "bookNow":
        return (
          <div className="faq-content">
            <p>Fill out the form to get started with our services.</p>
            <a href="/form" className="btn-book">
              Fill Out the Form
            </a>
          </div>
        );
      case "resources":
        return (
          <div className="faq-content">
            <ul>
              <li>
                <a href="https://bot.slerf.tools/en-us/market-making/solana" target="_blank" rel="noopener noreferrer">
                  Solana Market Making - Slerf Tools
                </a>
              </li>
              <li>
                <a href="https://asymmetry.pro/" target="_blank" rel="noopener noreferrer">
                  Asymmetry.pro - Solana Market Cap Management
                </a>
              </li>
              <li>
                <a href="https://marketplace.dexscreener.com/" target="_blank" rel="noopener noreferrer">
                  DEX Screener Marketplace
                </a>
              </li>
              <li>
                <a href="https://support.coinmarketcap.com/hc/en-us/sections/360008843692-Listings-Request-Forms" target="_blank" rel="noopener noreferrer">
                  CoinMarketCap Listing Request Forms
                </a>
              </li>
              <li>
                <a href="https://support.coingecko.com/hc/en-us/categories/7684622718105-CoinGecko-Request-Forms" target="_blank" rel="noopener noreferrer">
                  CoinGecko Listing Request Forms
                </a>
              </li>
            </ul>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="Home">
      <header className="header">
       <img src="/logo.png" alt="Logo" className="logo" />
        <a href="/form" className="btn-primary">Get Started</a>
      </header>

      <div className="faq-section">
        <div className="faq-container">
          <div className="faq-topics">
            <div
              className={`faq-title ${activeTopic === "about" ? "active" : ""}`}
              onClick={() => toggleFaq("about")}
            >
              About Cabal Marketing
            </div>
            <div
              className={`faq-title ${activeTopic === "services" ? "active" : ""}`}
              onClick={() => toggleFaq("services")}
            >
              Our Services
            </div>
            <div
              className={`faq-title ${activeTopic === "resources" ? "active" : ""}`}
              onClick={() => toggleFaq("resources")}
            >
              Resources
            </div>
            <div
              className={`faq-title ${activeTopic === "bookNow" ? "active" : ""}`}
              onClick={() => toggleFaq("bookNow")}
            >
              Book Now
            </div>
          </div>
          {activeTopic && (
            <div className="faq-display">{getContent()}</div>
          )}
        </div>
      </div>

      <footer className="footer">
        <p>&copy; 2024 Cabal Marketing. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;